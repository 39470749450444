// Importing default theme color object to mutate it
import defaultColors from '@elegantstack/solid-ui-theme/src/colors'

// Using tailwind preset colors
import colors from '@elegantstack/solid-ui-theme/src/color-preset'

export default {
    ...defaultColors,

    // Alpha (primary)
    alphaLighter: colors.darkGreen[100],
    alphaLight: colors.darkGreen[300],
    alpha: colors.darkGreen[800],
    alphaDark: colors.darkGreen[800],
    alphaDarker: colors.darkGreen[800],
    // beta (secondary)
    betaLighter: colors.lightGreen[100],
    betaLight: colors.lightGreen[300],
    beta: colors.lightGreen[900],
    betaDark: colors.lightGreen[900],
    betaDarker: colors.lightGreen[900]

}